<template>
  <div class="h-100">
    <b-row class="h-100">
      <b-col style="background-color:#D8ECFD;" class="pl-8 pr-0 align-items-center justify-content-center col-lg-6 d-flex">
        <img src="/assets/images/login-island.svg" alt="" width="100%" style="z-index:0;"/>
        <div style="z-index:0;position:absolute;text-align:center; ">
          <h1 style="font-size:64px;font-weight:bold;color:#333333;">SIORMAS</h1>
          <p style="font-weight: 600;font-size: 24px;color:#333333;">Sistem Informasi Organisasi Kemasyarakatan</p>
        </div>

      </b-col>
      <b-col style="background-color:white;" class="col-lg-6">
        <div class="d-flex justify-content-center" style="margin-top:32px;margin-bottom:30px;">
          <img src="/assets/images/logo1.svg" alt="" width="201px" height="87px" />
        </div>
        <b-row style="padding-left:51px;padding-right:51px;">
          <b-col>
            <b-card class="shadow" style="padding-left:2rem;padding-right:2rem;">
              <div style="margin-top:8px;margin-bottom:22px;font-weight: 600;font-size: 20px;" class="d-flex justify-content-center">
                Buat Sandi Baru
              </div>

              <b-row>
                <b-col>
                  <b-form-group id="input-group-newPass" label-for="new-password">
                    <template v-slot:label>
                      Kata Sandi Baru
                    </template>
                    <b-form-input
                      id="new-password"
                      name="new-password"
                      type="password"
                      v-model="$v.forget.pass.$model"
                      placeholder="Silahkan Masukan Kata sandi baru"
                      :state="validateState('pass')"
                      aria-describedby="new-password"
                    ></b-form-input>

                    <b-form-invalid-feedback v-if="!$v.forget.pass.required" id="new-password">Kata sandi wajib diisi</b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.forget.pass.minLength" id="new-password">Minimal 6 karakter</b-form-invalid-feedback>

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col>
                  <b-form-group id="input-group-confirmPass" label-for="confirm-password">
                    <template v-slot:label>
                      Konfirmasi Kata Sandi Baru
                    </template>
                    <b-form-input
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    v-model="$v.forget.conf_pass.$model"
                    placeholder="Silahkan Konfirmasi Kata sandi"
                    :state="validateState('conf_pass')"
                    aria-describedby="confirm-password"
                    ></b-form-input>
                    
                    <b-form-invalid-feedback v-if="!$v.forget.conf_pass.required" id="confirm-password">Konfirmasi Kata sandi wajib diisi</b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.forget.conf_pass.sameAsPassword" id="confirm-password">Konfirmasi Kata sandi tidak sesuai</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col class="d-flex justify-content-center">
                  <b-button @click="formMode = 'login'" size="lg" variant="outline-primary" style="width:190px;" class="mr-3">
                    Batal
                  </b-button>
                  <b-button @click="submitForget()" size="lg" variant="primary" style="width:190px;">
                    Kirim
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login-kesbangpol",
  data(){
    return {
      loading: false,
      forget: {
        conf_pass: '',
        pass: ''
      }
    }
  },
  validations: {
    forget: {
      pass: { required, minLength: minLength(6) },
      conf_pass: { required, sameAs: sameAs("pass") }
    }
  },
  mounted() {
    // Validasi token
    this.validateToken()

    if(localStorage.getItem('token')) {
      this.$store
        .dispatch("getProfile").then(()=>{
          this.$router.push('/dashboard')
        }).catch(()=>{
          localStorage.removeItem('token')
          location.reload()
        })
    };

    // Enable recaptcha badge
    setTimeout(() => {
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
    }, 500)
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.forget[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.forget = {
        pass: null,
        conf_pass: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    submitForget: function() {
      const pass = this.forget.pass;
      const confirm_pass = this.forget.conf_pass;
      const token = this.$route.query.token;

      axios.post(`${process.env.VUE_APP_APPLICATION_SIORMAS_URL}/lupa-kata-sandi/ubah-sandi/${token}`, {
          password: pass,
          confirm_password: confirm_pass,
          nonOrmas: true
        }
      )
      .then(response => {
          Swal.fire({
            icon: "success",
            title: "Buat kata sandi berhasil, silahkan login",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Login"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/polpum');
            }
          });
        return response
      })
      .catch(error => {
        Swal.fire({
          icon: "error",
          title: error.response.data.meta?.message ? error.response.data.meta.message : "Cek kembali isian anda",
          // text: "Silahkan cek email anda untuk melihat sandi anda",
          confirmButtonColor: "#063A69",
          confirmButtonText: "Ok"
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      });
      
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
  
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
    
        return token
    },
    validateToken() {
      const token = this.$route.query.token;

      this.$store
        .dispatch("validasiTokenLupas", token)
        .then((res) => {
          const data = res.data;

          if (data.meta.valid == false) {
            this.status = 'error'
            this.resp = data

            Swal.fire({
              icon: 'warning',
              title: data.message,
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok"
            });

            this.$router.push('/login');
          }
          
        })
        .catch(error => {
          this.status = 'error'
          this.resp = error
          Swal.fire({
            icon: 'warning',
            title: error.message,
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok"
          });

          this.$router.push('/login');
        });
    }
  },
  beforeDestroy() {
    // Hide badge recaptcha
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  }
};
</script>
